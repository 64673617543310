.ag-theme-quartz {
  --ag-active-color: #00d79c;
  --ag-wrapper-border-radius: 6px;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.ag-row .ag-cell-right-aligned {
  display: flex;
  align-items: center;
  justify-content: right;
}

.ag-row .ag-cell-centre-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-row .ag-menu-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ag-menu-option {
  cursor: pointer;
}

.ag-header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.ag-paging-row-summary-panel::before {
  content: "Showing ";
}

.ag-paging-row-summary-panel::after {
  content: " rows";
}

.ag-details-row {
  padding: 10px;
  background-color: lightgray;
}

.ag-body .ag-body-viewport {
  width: 100% !important;
}

.ag-floating-bottom-viewport .ag-floating-bottom-container .ag-column-last {
  direction: rtl;
  overflow: visible;
}
